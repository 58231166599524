import { type I18nTranslation, useExtendI18nTranslation } from '@bluemarker/core';
import {
  BluemarkerFullLogo,
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  SideWallpaperLayout,
} from '@bluemarker/ui';
import { HomeIcon } from '@bluemarker/ui/icons';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router';

const TRANSLATION: I18nTranslation = {
  en: {
    pageNotFound: 'Page not found',
    pageNotFoundDescription: 'The page you are looking for may not be available or does not exist',
    backToHomepage: 'Back to homepage',
  },
  vi: {
    pageNotFound: 'Không tìm thấy trang',
    pageNotFoundDescription: 'Trang bạn tìm kiếm có thể không khả dụng hoặc không tồn tại',
    backToHomepage: 'Trở về trang chủ',
  },
};

const NotFoundPage = () => {
  const { t } = useExtendI18nTranslation(TRANSLATION);

  return (
    <>
      <Helmet>
        <title>Bluemarker | {t('pageNotFound')}</title>
      </Helmet>
      <SideWallpaperLayout>
        <Card className="border-none shadow-none animate-in slide-in-from-bottom-full fade-in-0 duration-1000 z-10">
          <CardHeader>
            <Link className="w-fit" to={import.meta.env.VITE_LANDING_PAGE_URL} target="__blank" rel="noreferrer">
              <div className="h-12 max-w-52 flex items-center justify-center">
                <BluemarkerFullLogo />
              </div>
            </Link>
          </CardHeader>
          <CardContent className="text-center">
            <h1 className="font-bold text-8xl bg-gradient-to-r from-primary-4 to-primary-8 inline-block text-transparent bg-clip-text mb-4">
              404
            </h1>
            <p className="text-lg">{t('pageNotFound')}</p>
            <p className="text-neutral-5 text-sm">{t('pageNotFoundDescription')}</p>
          </CardContent>
          <CardFooter className="flex justify-center">
            <Link className="w-fit" to={import.meta.env.VITE_LANDING_PAGE_URL}>
              <Button variant="secondary" className="text-base py-2 h-fit">
                <HomeIcon className="w-6 h-6 mr-2" /> {t('backToHomepage')}
              </Button>
            </Link>
          </CardFooter>
        </Card>
      </SideWallpaperLayout>
    </>
  );
};

export default NotFoundPage;

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { QUERY_CLIENT } from '@bluemarker/core';
import { ThemeProvider } from '@bluemarker/ui';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter } from 'react-router';
import { RouterProvider } from 'react-router/dom';
import { load } from 'webfontloader';

import './index.css';
import { routes } from './pages/routes';

load({
  google: {
    families: [
      'Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900',
    ],
  },
});

const router = createBrowserRouter(routes);

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: import.meta.env.VITE_AZURE_OAUTH_CLIENT_ID,
    redirectUri: '/close',
  },
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={QUERY_CLIENT}>
        <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID}>
          <MsalProvider instance={msalInstance}>
            <ThemeProvider>
              <RouterProvider router={router} />
            </ThemeProvider>
          </MsalProvider>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>
);

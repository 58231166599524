import { type I18nTranslation, useExtendI18nTranslation } from '@bluemarker/core';
import { BluemarkerFullLogo, Card, CardContent, CardFooter, CardHeader, SideWallpaperLayout } from '@bluemarker/ui';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useSearchParams } from 'react-router';

import SignInExpirationAlert from './sign-in-card/sign-in-expiration-alert';
import SignInInstruction from './sign-in-card/sign-in-instruction';
import SignInPrivacyPolicy from './sign-in-card/sign-in-privacy-policy';
import SignInRedirect from './sign-in-card/sign-in-redirect';
import SignInUnauthenticatedAlert from './sign-in-card/sign-in-unauthenticated-alert';
import SignInWithGoogleButton from './sign-in-card/sign-in-with-google-button';
import SignInWithMicrosoftButton from './sign-in-card/sign-in-with-microsoft-button';

const TRANSLATION: I18nTranslation = {
  en: {
    signIn: 'Sign In',
  },
  vi: {
    signIn: 'Đăng nhập',
  },
};

const SignInAdmin = () => {
  const { t } = useExtendI18nTranslation(TRANSLATION);
  const [redirecting, setRedirecting] = useState(false);
  const [_, setSearchParams] = useSearchParams();

  const handleSignInError = () => {
    setSearchParams({
      error: 'unauthenticated',
    });
  };

  const handleSignInSuccess = () => {
    setRedirecting(true);
    window.location.href = import.meta.env.VITE_ADMIN_PLATFORM_URL;
  };

  return (
    <>
      <Helmet>
        <title>Bluemarker Admin | {t('signIn')}</title>
      </Helmet>
      <SideWallpaperLayout bluemarkerPlatform="Admin Platform">
        <Card className="w-full border-none shadow-none animate-in slide-in-from-bottom-full fade-in-0 duration-1000 z-10">
          <div className="px-6">
            <SignInUnauthenticatedAlert />
            <SignInExpirationAlert />
          </div>
          <CardHeader>
            <Link className="w-fit" to={import.meta.env.VITE_LANDING_PAGE_URL} target="__blank" rel="noreferrer">
              <div className="h-12 max-w-52">
                <BluemarkerFullLogo />
                <p className="text-xs">Admin Platform</p>
              </div>
            </Link>
          </CardHeader>
          {!redirecting ? (
            <>
              <CardContent>
                <SignInInstruction />

                <div className="flex flex-col gap-4">
                  <SignInWithGoogleButton context="admin" onSuccess={handleSignInSuccess} onError={handleSignInError} />
                  <SignInWithMicrosoftButton
                    context="admin"
                    onSuccess={handleSignInSuccess}
                    onError={handleSignInError}
                  />
                </div>
              </CardContent>

              <CardFooter className="justify-center">
                <SignInPrivacyPolicy />
              </CardFooter>
            </>
          ) : (
            <CardContent>
              <SignInRedirect />
            </CardContent>
          )}
        </Card>
      </SideWallpaperLayout>
    </>
  );
};

export default SignInAdmin;

import { useExtendI18nTranslation } from '@bluemarker/core';
import { Alert, AlertDescription, AlertTitle } from '@bluemarker/ui';
import { CircleAlertIcon } from '@bluemarker/ui/icons';
import { useSearchParams } from 'react-router';

const UNAUTHENTICATED_ERROR = 'unauthenticated';

const TRANSLATION = {
  en: {
    signInUnauthenticatedTitle: 'Unauthenticated',
    signInUnauthenticatedDescription: 'Please contact your center for further support',
  },
  vi: {
    signInUnauthenticatedTitle: 'Không thể xác thực',
    signInUnauthenticatedDescription: 'Vui lòng liên hệ lại với trung tâm của bạn để được hỗ trợ',
  },
};

const SignInUnauthenticatedAlert = () => {
  const [searchParams] = useSearchParams();
  const { t } = useExtendI18nTranslation(TRANSLATION);

  const errorSessionExpired = searchParams.get('error') === UNAUTHENTICATED_ERROR;

  return errorSessionExpired ? (
    <Alert className="animate-in fade-in-0 bg-danger-4/5" variant="danger">
      <CircleAlertIcon className="h-5 w-5" />
      <AlertTitle>{t('signInUnauthenticatedTitle')}</AlertTitle>
      <AlertDescription>{t('signInUnauthenticatedDescription')}</AlertDescription>
    </Alert>
  ) : null;
};

export default SignInUnauthenticatedAlert;

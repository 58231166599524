'use client';

import { type I18nTranslation, useExtendI18nTranslation } from '@bluemarker/core';
import { renderToString } from 'react-dom/server';

import { BluemarkerFullLogo, WhiteSvgImage } from '../../components';
import { cn, svgToImageUTF8 } from '../../utils';

type AnimatedBackgroundProps = {
  className?: string;
  logoUrl?: string;
  bluemarkerPlatform?: string;
};

const TRANSLATION: I18nTranslation = {
  en: {
    bluemarkerIntroduction:
      'The leading educational platform in managing and preparing for English certification exams using AI in Vietnam',
  },
  vi: {
    bluemarkerIntroduction:
      'Nền tảng giáo dục đi đầu trong việc quản lý và luyện thi chứng chỉ tiếng anh ứng dụng AI ở Việt Nam',
  },
};

const AnimatedBackground = ({ className, logoUrl, bluemarkerPlatform }: AnimatedBackgroundProps) => {
  const { t } = useExtendI18nTranslation(TRANSLATION);

  const allStars = new Array(20).fill(0);
  const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * max);
  };

  return (
    <>
      <style>
        {`
          @keyframes raising {
            0% {
              transform: translateY(0);
              opacity: 1;
            }

            100% {
              transform: translateY(-100vh);
              opacity: 0;
            }
        }
        `}
      </style>
      <div
        className={cn(
          'animate-in fade-in-0 duration-500 bg-gradient-to-br from-primary-4 via-primary-4/90 to-primary-4/80 relative w-full h-full overflow-hidden',
          className
        )}
      >
        <span className="absolute z-10 top-16 left-16">
          <WhiteSvgImage src={logoUrl ?? svgToImageUTF8(renderToString(<BluemarkerFullLogo />))} />
          {bluemarkerPlatform && <p className="text-xs text-neutral-0">{bluemarkerPlatform}</p>}
        </span>

        {allStars.map((_, i) => (
          <span
            className="absolute block list-none aspect-square w-2.5 bg-neutral-8/30 -bottom-[10rem] rounded-[10%]"
            key={i}
            style={{
              width: 5 * i + 1,
              left: `${getRandomInt(100)}vw`,
              bottom: `-${5 * allStars.length + 5}px`,
              animationDuration: `${getRandomInt(20) + 10}s`,
              animationDelay: `${getRandomInt(10)}s`,
              animationName: 'raising', // Separate property for animation name
              animationTimingFunction: 'linear', // Separate property for timing function
              animationIterationCount: 'infinite', // Separate property for iteration count
            }}
          />
        ))}

        <blockquote className="w-full p-16 bottom-0 absolute">
          <p className="text-2xl leading-relaxed tracking-tighter text-neutral-0 font-light line-clamp-2">
            {t('bluemarkerIntroduction')}
          </p>
        </blockquote>
      </div>
    </>
  );
};

export default AnimatedBackground;

'use client';

import { type PropsWithChildren, useMemo, useState } from 'react';

import { createContext } from '../../utils';
import { DEFAULT_THEME } from './constant';
import type { Theme } from './type';

export type ThemeContext = {
  theme: Theme;
  setTheme: (partial: Partial<Theme>) => void;
};

const [useThemeContext, ThemeContextProvider] = createContext<ThemeContext>();

export { useThemeContext };

function sanitizeHexColor(hex: string): string {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    throw Error('Invalid hex color');
  }
  return `${parseInt(result[1]!, 16)} ${parseInt(result[2]!, 16)} ${parseInt(result[3]!, 16)}`;
}

const ThemeProvider = ({ children }: PropsWithChildren) => {
  const [theme, setTheme] = useState<Theme>(DEFAULT_THEME);

  const value = useMemo<ThemeContext>(
    () => ({
      theme,
      setTheme: (partialTheme: Partial<Theme>) => {
        setTheme({
          neutral: partialTheme.neutral ?? DEFAULT_THEME.neutral,
          primary: partialTheme.primary ?? DEFAULT_THEME.primary,
          success: partialTheme.success ?? DEFAULT_THEME.success,
          info: partialTheme.info ?? DEFAULT_THEME.info,
          warning: partialTheme.warning ?? DEFAULT_THEME.warning,
          danger: partialTheme.danger ?? DEFAULT_THEME.danger,
        });
      },
    }),
    [theme]
  );

  return (
    <ThemeContextProvider value={value}>
      <style>
        {`
          :root {
            --neutral-0: ${sanitizeHexColor(theme.neutral[0])};
            --neutral-1: ${sanitizeHexColor(theme.neutral[1])};
            --neutral-2: ${sanitizeHexColor(theme.neutral[2])};
            --neutral-3: ${sanitizeHexColor(theme.neutral[3])};
            --neutral-4: ${sanitizeHexColor(theme.neutral[4])};
            --neutral-5: ${sanitizeHexColor(theme.neutral[5])};
            --neutral-6: ${sanitizeHexColor(theme.neutral[6])};
            --neutral-7: ${sanitizeHexColor(theme.neutral[7])};
            --neutral-8: ${sanitizeHexColor(theme.neutral[8])};

            --primary-0: ${sanitizeHexColor(theme.primary[0])};
            --primary-1: ${sanitizeHexColor(theme.primary[1])};
            --primary-2: ${sanitizeHexColor(theme.primary[2])};
            --primary-3: ${sanitizeHexColor(theme.primary[3])};
            --primary-4: ${sanitizeHexColor(theme.primary[4])};
            --primary-5: ${sanitizeHexColor(theme.primary[5])};
            --primary-6: ${sanitizeHexColor(theme.primary[6])};
            --primary-7: ${sanitizeHexColor(theme.primary[7])};
            --primary-8: ${sanitizeHexColor(theme.primary[8])};

            --info-0: ${sanitizeHexColor(theme.info[0])};
            --info-1: ${sanitizeHexColor(theme.info[1])};
            --info-2: ${sanitizeHexColor(theme.info[2])};
            --info-3: ${sanitizeHexColor(theme.info[3])};
            --info-4: ${sanitizeHexColor(theme.info[4])};
            --info-5: ${sanitizeHexColor(theme.info[5])};
            --info-6: ${sanitizeHexColor(theme.info[6])};
            --info-7: ${sanitizeHexColor(theme.info[7])};
            --info-8: ${sanitizeHexColor(theme.info[8])};

            --success-0: ${sanitizeHexColor(theme.success[0])};
            --success-1: ${sanitizeHexColor(theme.success[1])};
            --success-2: ${sanitizeHexColor(theme.success[2])};
            --success-3: ${sanitizeHexColor(theme.success[3])};
            --success-4: ${sanitizeHexColor(theme.success[4])};
            --success-5: ${sanitizeHexColor(theme.success[5])};
            --success-6: ${sanitizeHexColor(theme.success[6])};
            --success-7: ${sanitizeHexColor(theme.success[7])};
            --success-8: ${sanitizeHexColor(theme.success[8])};

            --warning-0: ${sanitizeHexColor(theme.warning[0])};
            --warning-1: ${sanitizeHexColor(theme.warning[1])};
            --warning-2: ${sanitizeHexColor(theme.warning[2])};
            --warning-3: ${sanitizeHexColor(theme.warning[3])};
            --warning-4: ${sanitizeHexColor(theme.warning[4])};
            --warning-5: ${sanitizeHexColor(theme.warning[5])};
            --warning-6: ${sanitizeHexColor(theme.warning[6])};
            --warning-7: ${sanitizeHexColor(theme.warning[7])};
            --warning-8: ${sanitizeHexColor(theme.warning[8])};

            --danger-0: ${sanitizeHexColor(theme.danger[0])};
            --danger-1: ${sanitizeHexColor(theme.danger[1])};
            --danger-2: ${sanitizeHexColor(theme.danger[2])};
            --danger-3: ${sanitizeHexColor(theme.danger[3])};
            --danger-4: ${sanitizeHexColor(theme.danger[4])};
            --danger-5: ${sanitizeHexColor(theme.danger[5])};
            --danger-6: ${sanitizeHexColor(theme.danger[6])};
            --danger-7: ${sanitizeHexColor(theme.danger[7])};
            --danger-8: ${sanitizeHexColor(theme.danger[8])};
          }
        `}
      </style>
      {children}
    </ThemeContextProvider>
  );
};

export default ThemeProvider;

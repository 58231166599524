import type { RouteObject } from 'react-router';

import ClosePage from './close.page';
import NotFoundPage from './not-found.page';
import SignInPage from './sign-in/sign-in.page';

export const routes: RouteObject[] = [
  {
    path: '/',
    ErrorBoundary: NotFoundPage,
    children: [
      {
        path: '/404',
        Component: NotFoundPage,
      },
      {
        path: '/close',
        Component: ClosePage,
      },
      {
        path: '/',
        Component: SignInPage,
      },
      {
        path: '/:subdomain',
        Component: SignInPage,
      },
    ],
  },
];

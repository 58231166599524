import { type I18nTranslation, useExtendI18nTranslation } from '@bluemarker/core';
import { Card, CardContent, CardFooter, CardHeader, SideWallpaperLayout, useThemeContext } from '@bluemarker/ui';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useSearchParams } from 'react-router';

import type { OrganizationProfile } from '@/apis/organization/use-query-organization-profile';

import SignInExpirationAlert from './sign-in-card/sign-in-expiration-alert';
import SignInInstruction from './sign-in-card/sign-in-instruction';
import SignInPrivacyPolicy from './sign-in-card/sign-in-privacy-policy';
import SignInRedirect from './sign-in-card/sign-in-redirect';
import SignInUnauthenticatedAlert from './sign-in-card/sign-in-unauthenticated-alert';
import SignInWithGoogleButton from './sign-in-card/sign-in-with-google-button';
import SignInWithMicrosoftButton from './sign-in-card/sign-in-with-microsoft-button';

const TRANSLATION: I18nTranslation = {
  en: {
    signIn: 'Sign In',
    acknowledgement: 'By signing in, you are agreeing to our ',
    privacyPolicy: 'Privacy Policy',
    our: '',
    redirecting: 'Redirecting',
  },
  vi: {
    signIn: 'Đăng nhập',
    acknowledgement: 'Bằng việc đăng nhập tài khoản, bạn đã đồng ý với ',
    privacyPolicy: 'Chính sách bảo mật',
    our: 'của chúng tôi',
    redirecting: 'Đang điều hướng',
  },
};

type SignInOrganizationProps = {
  organizationProfile: OrganizationProfile;
};

const SignInOrganization = ({ organizationProfile }: SignInOrganizationProps) => {
  const { t } = useExtendI18nTranslation(TRANSLATION);
  const { setTheme } = useThemeContext();
  const [redirecting, setRedirecting] = useState(false);
  const [_, setSearchParams] = useSearchParams();

  const handleSignInError = () => {
    setSearchParams({
      error: 'unauthenticated',
    });
  };

  const handleSignInSuccess = () => {
    setRedirecting(true);
    window.location.href = import.meta.env.VITE_TUTOR_PLATFORM_URL.replace('sample', organizationProfile.subdomain);
  };

  useEffect(() => {
    organizationProfile.theme && setTheme(organizationProfile.theme);
  }, [organizationProfile]);

  return (
    <>
      <Helmet>
        <title>
          {organizationProfile.name} | {t('signIn')}
        </title>
        <link rel="icon" href={organizationProfile.faviconUrl}></link>
      </Helmet>

      <SideWallpaperLayout logoUrl={organizationProfile.logoUrl} wallpaperUrl={organizationProfile.wallpaperUrl}>
        <Card className="w-full border-none shadow-none animate-in slide-in-from-bottom-full fade-in-0 duration-1000 z-10">
          <div className="px-6">
            <SignInUnauthenticatedAlert />
            <SignInExpirationAlert />
          </div>
          <CardHeader className="py-10">
            <Link className="w-fit" to={organizationProfile.websiteUrl} target="__blank" rel="noreferrer">
              <div className="h-12 max-w-52 w-fit">
                <img alt="organization-logo" className="h-full max-w-full" src={organizationProfile.logoUrl} />
              </div>
            </Link>
          </CardHeader>
          {!redirecting ? (
            <>
              <CardContent>
                <SignInInstruction />

                <div className="flex flex-col gap-4">
                  <SignInWithGoogleButton
                    context={organizationProfile.subdomain}
                    onSuccess={handleSignInSuccess}
                    onError={handleSignInError}
                  />
                  <SignInWithMicrosoftButton
                    context={organizationProfile.subdomain}
                    onSuccess={handleSignInSuccess}
                    onError={handleSignInError}
                  />
                </div>
              </CardContent>

              <CardFooter className="justify-center">
                <SignInPrivacyPolicy />
              </CardFooter>
            </>
          ) : (
            <CardContent>
              <SignInRedirect />
            </CardContent>
          )}
        </Card>
      </SideWallpaperLayout>
    </>
  );
};

export default SignInOrganization;
